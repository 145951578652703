import { Navigate, useLocation, useParams } from 'react-router-dom';
import { APICore } from 'helpers/api/apiCore';
import SecurityHelper from '../helpers/SecurityHelper';
import { Role } from '../appConstants';

type PrivateRouteProps = {
    component?: React.ComponentType;
    element?: JSX.Element;
    roles?: Role[];
    availableIf?: Function;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
    component: RouteComponent,
    element: RouteElement,
    roles,
    availableIf,
    ...rest
}: PrivateRouteProps) => {
    let location = useLocation();

    const api = new APICore();
    const params = useParams();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    if (roles && !SecurityHelper.hasAnyRole(roles)) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/' }} />;
    }

    if (availableIf && !availableIf(params)) {
        return <Navigate to={{ pathname: '/' }} />;
    }

    if (RouteComponent) {
        return <RouteComponent />;
    }

    if (RouteElement) {
        return RouteElement;
    }

    return null;
};

export default PrivateRoute;
