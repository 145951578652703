import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth/reducers';
import layoutReducer from './layout/reducers';
import authReducer from './auth/authSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { sojusApi } from '../api';
import { setupListeners } from '@reduxjs/toolkit/query';
import { unauthenticatedMiddleware } from '../helpers/api/unauthenticatedMiddleware';

const persistConfig = {
    key: 'sojus',
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const reducer = {
    Auth: AuthReducer,
    Layout: layoutReducer,
    auth: persistedAuthReducer,
    [sojusApi.reducerPath]: sojusApi.reducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware: any) =>
        getDefaultMiddleware().concat([sojusApi.middleware, unauthenticatedMiddleware]),
});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
