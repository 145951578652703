import Routes from 'routes/Routes';
import { configureFakeBackend } from 'helpers';
import { loadProgressBar } from 'axios-progress-bar';
import momentFormationFormatSetup from 'moment-duration-format';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';
// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';
import 'react-datetime/css/react-datetime.css';
import { useEffect } from 'react';
import * as moment from 'moment-timezone';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';

const App = () => {
    const useDarkMode = useSelector((state: RootState) => state.auth.useDarkMode);

    useEffect(() => {
        momentFormationFormatSetup(moment);
        moment.tz.setDefault('Europe/Berlin');
        loadProgressBar({
            speed: 800,
        });
    }, []);
    configureFakeBackend();
    return (
        <>
            <ToastContainer
                theme={useDarkMode ? 'dark' : 'colored'}
                autoClose={1500}
                position={'top-right'}
                draggable={false}
                closeOnClick={true}
            />
            <Routes />
        </>
    );
};

export default App;
