/* Layout types and other constants */

export enum LayoutTypes {
    LAYOUT_VERTICAL = 'vertical',
    LAYOUT_HORIZONTAL = 'topnav',
    LAYOUT_DETACHED = 'detached',
    LAYOUT_FULL = 'full',
}

export enum LayoutColor {
    LAYOUT_COLOR_LIGHT = 'light',
    LAYOUT_COLOR_DARK = 'dark',
}

export enum LayoutWidth {
    LAYOUT_WIDTH_FLUID = 'fluid',
    LAYOUT_WIDTH_BOXED = 'boxed',
}

export enum SideBarTheme {
    LEFT_SIDEBAR_THEME_DEFAULT = 'default',
    LEFT_SIDEBAR_THEME_LIGHT = 'light',
    LEFT_SIDEBAR_THEME_DARK = 'dark',
}

export enum SideBarWidth {
    LEFT_SIDEBAR_TYPE_FIXED = 'fixed',
    LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed',
    LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable',
}

export const PROJECT_COLORS = [
    {
        name: 'amber',
        displayName: 'Amber',
        hex: '#F5BC00',
        textColor: '#000000',
    },
    {
        name: 'jade',
        displayName: 'Jade',
        hex: '#0EAD69',
        textColor: '#000000',
    },
    {
        name: 'forest_green',
        displayName: 'Forest green',
        hex: '#498b39',
        textColor: '#000000',
    },
    {
        name: 'periwinkle',
        displayName: 'Periwinkle',
        hex: '#9799FF',
        textColor: '#000000',
    },
    {
        name: 'ultra_violet',
        displayName: 'Ultra Violet',
        hex: '#5353A2',
        textColor: '#FFFFFF',
    },
    {
        name: 'mauve',
        displayName: 'Mauve',
        hex: '#C9188E',
        textColor: '#FFFFFF',
    },
    {
        name: 'maroon',
        displayName: 'Maroon',
        hex: '#850000',
        textColor: '#FFFFFF',
    },
];
