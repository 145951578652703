import { APICore } from './apiCore';

const api = new APICore();

function login(params: { username: string; password: string; secondFactorValidityToken?: string }) {
    const baseUrl = '/login';
    params.username = params.username?.toLowerCase();
    return api.create(`${baseUrl}`, params);
}

function secondFactor(params: { token: string }) {
    return api.create('/v2/user/secondFactor', params);
}

function forgotPassword(params: { username: string }) {
    const baseUrl = '/v2/user/request-reset-password';
    params.username = params.username?.toLowerCase();
    return api.create(`${baseUrl}`, params);
}

function validateResetPasswordToken(params: { token: string }) {
    return api.get(`/v2/user/validateResetPasswordToken/${params.token}`);
}

function resetPassword(params: { token: string; password: string }) {
    const baseUrl = '/v2/user/resetPassword/' + params.token;
    return api.create(`${baseUrl}`, params);
}

function refreshAccessToken(params: { refreshToken: string }) {
    const baseUrl = `/oauth/access_token?grant_type=refresh_token&refresh_token=${params.refreshToken}`;
    return api.create(baseUrl, {});
}

export { login, secondFactor, forgotPassword, validateResetPasswordToken, resetPassword, refreshAccessToken };
