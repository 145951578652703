import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, AxiosRequestConfig, Method } from 'axios';

const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        | string
        | {
              url: string;
              method: AxiosRequestConfig['method'];
              body?: AxiosRequestConfig['data'];
              params?: AxiosRequestConfig['params'];
          },
        unknown,
        unknown
    > =>
    async (input: string | { url: string; method: Method; body: any; params: any }) => {
        try {
            let requestConfig: any = {};
            if (typeof input === 'string') {
                requestConfig = {
                    url: baseUrl + input,
                    method: 'GET',
                };
            } else {
                requestConfig = {
                    ...input,
                    data: input.body,
                };
            }
            const result = await axios(requestConfig);
            return { data: result.data };
        } catch (axiosError) {
            let err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export const sojusApi = createApi({
    reducerPath: 'sojusApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        'Accounting',
        'AccountingList',
        'AccountingOption',
        'AccountingOptionsForUser',
        'ApiKeyList',
        'ArrangementType',
        'ArrangementTypeList',
        'BackstageList',
        'CashTemplateList',
        'CheckIn',
        'CheckInList',
        'Crew',
        'CrewList',
        'CrewAdminList',
        'CrewType',
        'DashboardData',
        'DashboardDataAttendances',
        'DashboardDataTicketing',
        'KitchenList',
        'Person',
        'PersonAccountingComments',
        'PersonAttendance',
        'PersonList',
        'PersonData',
        'PersonalData',
        'PersonRoleList',
        'Project',
        'ProjectBackstage',
        'ProjectBackstageList',
        'ProjectCashAmount',
        'ProjectCashDealType',
        'ProjectCashPeriodGroup',
        'ProjectCashTemplate',
        'ProjectCrew',
        'ProjectCrewAdmin',
        'ProjectCrewArrangement',
        'ProjectCrewDealCalculation',
        'ProjectCrewLogbook',
        'ProjectCrewLogbookList',
        'ProjectCrewManager',
        'ProjectCrewMappedArrangement',
        'ProjectCrewPersonConfirmedWorkingDay',
        'ProjectCrewPersonProjectCrewProjectKitchenPeriod',
        'ProjectCrewPersonWorkingPeriod',
        'ProjectCrewProjectCashDealType',
        'ProjectCrewStewardList',
        'ProjectCrewSupporterDemand',
        'ProjectCrewTicketing',
        'ProjectKitchen',
        'ProjectKitchenList',
        'ProjectList',
        'ProjectNews',
        'ProjectPretixConfig',
        'RoleList',
        'StewardInstruction',
        'StewardList',
        'SupporterDemand',
        'SupporterDemandList',
        'TicketOptionType',
        'TicketOptionTypeList',
        'TicketToasterCurrency',
        'TicketType',
        'TicketTypeList',
        'UserType',
        'WorkingStatus',
        'Wristband',
        'WristbandList',
    ],
    endpoints: () => ({}),
});
