import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationDe from 'locales/de/translation.json';
import translationEn from 'locales/en/translation.json';

// translations
const resources = {
    de: {
        translation: translationDe,
    },
    en: {
        translation: translationEn,
    },
};

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,

        fallbackLng: 'de',
        lng: 'de',

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
