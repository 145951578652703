import React, { useRef } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, DetachedLayout, FullLayout, HorizontalLayout, VerticalLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes, Role } from 'appConstants';
import { useRedux } from 'hooks';
import LoadComponent from 'components/LoadComponent';
import SecurityHelper from '../helpers/SecurityHelper';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));
const SecondFactor = React.lazy(() => import('pages/account/SecondFactor'));
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'));
const SetPassword = React.lazy(() => import('pages/account/SetPassword'));
const PrivacyStatements = React.lazy(() => import('pages/account/PrivacyStatements'));
const OAuthSuccess = React.lazy(() => import('pages/account/OAuthSuccess'));

// pages
const Dashboard = React.lazy(() => import('pages/dashboard'));
const Persons = React.lazy(() => import('pages/persons'));
const Person = React.lazy(() => import('pages/persons/person'));
const PersonalSettings = React.lazy(() => import('pages/personalSettings'));
const ApiKeyManagement = React.lazy(() => import('pages/apiKeyManagement'));
const Projects = React.lazy(() => import('pages/projects'));
const Project = React.lazy(() => import('pages/projects/project'));
const CrewTypes = React.lazy(() => import('pages/crewTypes'));
const Crews = React.lazy(() => import('pages/crews'));
const Crew = React.lazy(() => import('pages/crews/crew'));
const MetaCrews = React.lazy(() => import('pages/metaCrews'));
const MetaCrew = React.lazy(() => import('pages/metaCrews/crew'));
const KitchenMonitor = React.lazy(() => import('pages/kitchenMonitor'));
const CrewAttendanceOverview = React.lazy(() => import('pages/crewAttendanceOverview'));
const Accountings = React.lazy(() => import('pages/accountings'));
const CheckIn = React.lazy(() => import('pages/checkIn'));
const Order = React.lazy(() => import('pages/checkIn/order'));
const StewardList = React.lazy(() => import('pages/stewards'));
const ProjectLog = React.lazy(() => import('pages/projectLog'));
const CrewNewsletter = React.lazy(() => import('pages/crewNewsletter'));
const Survey2017 = React.lazy(() => import('pages/surveys/2017'));
const Survey2023 = React.lazy(() => import('pages/surveys/2023'));
const SupporterDemands = React.lazy(() => import('pages/supporterDemands'));

const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

export type LayoutProps = {
    menuAutoClosing: React.MutableRefObject<string>;
};

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const autoClosing = useRef<string>('0');

    const getLayout = () => {
        let layoutCls: React.ComponentType<LayoutProps> = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'secondFactor/:token?', element: <LoadComponent component={SecondFactor} /> },
                        { path: 'resetPassword/:token', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'setPassword/:token', element: <LoadComponent component={SetPassword} /> },
                        { path: 'privacyStatements', element: <LoadComponent component={PrivacyStatements} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'oidc_success',
                    element: <LoadComponent component={OAuthSuccess} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute element={<Layout menuAutoClosing={autoClosing} />} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={Dashboard} />,
                },
                {
                    path: 'check-in',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_CHECK_IN]} element={LoadComponent({ component: CheckIn })} />
                    ),
                },
                {
                    path: 'check-in/:secret',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_CHECK_IN]} element={LoadComponent({ component: Order })} />
                    ),
                },
                {
                    path: 'crews',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_ADMIN, Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_PLENUM]}
                            element={LoadComponent({ component: Crews })}
                        />
                    ),
                },
                {
                    path: 'crews/:id',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_USER]}
                            availableIf={(params) =>
                                SecurityHelper.isCrewManagerOrHigher(params.id, [Role.ROLE_PLENUM])
                            }
                            element={LoadComponent({ component: Crew })}
                        />
                    ),
                },
                {
                    path: 'meta-crews',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_ADMIN, Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_PLENUM]}
                            element={LoadComponent({ component: MetaCrews })}
                        />
                    ),
                },
                {
                    path: 'meta-crews/:id',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_USER]}
                            availableIf={(params) =>
                                SecurityHelper.isCrewManagerOrHigher(params.id, [Role.ROLE_PLENUM])
                            }
                            element={LoadComponent({ component: MetaCrew })}
                        />
                    ),
                },
                {
                    path: 'personal-settings',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_USER]}
                            element={LoadComponent({ component: PersonalSettings })}
                        />
                    ),
                },
                {
                    path: 'personal-settings/api',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_API]}
                            element={LoadComponent({ component: ApiKeyManagement })}
                        />
                    ),
                },
                {
                    path: 'persons',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_ADMIN, Role.ROLE_CREW_CARE_ADMIN]}
                            element={LoadComponent({ component: Persons })}
                        />
                    ),
                },
                {
                    path: 'persons/:id',
                    element: <PrivateRoute roles={[Role.ROLE_USER]} element={LoadComponent({ component: Person })} />,
                },
                {
                    path: 'persons/:id/:accountingId',
                    element: <PrivateRoute roles={[Role.ROLE_USER]} element={LoadComponent({ component: Person })} />,
                },
                {
                    path: 'kitchen-monitor',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_ADMIN, Role.ROLE_CREW_CARE_ADMIN, Role.ROLE_ADDON_KITCHEN_MONITOR]}
                            element={LoadComponent({ component: KitchenMonitor })}
                        />
                    ),
                },
                {
                    path: 'crew-attendance-overview',
                    element: (
                        <PrivateRoute
                            roles={[
                                Role.ROLE_ADMIN,
                                Role.ROLE_CREW_CARE_ADMIN,
                                Role.ROLE_ADDON_CREW_ATTENDANCE_OVERVIEW,
                            ]}
                            element={LoadComponent({ component: CrewAttendanceOverview })}
                        />
                    ),
                },
                {
                    path: 'accountings',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_CASH_ASSISTANT]}
                            element={LoadComponent({ component: Accountings })}
                        />
                    ),
                },
                {
                    path: 'projects',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_ADMIN]} element={LoadComponent({ component: Projects })} />
                    ),
                },
                {
                    path: 'projects/:id',
                    element: <PrivateRoute roles={[Role.ROLE_ADMIN]} element={LoadComponent({ component: Project })} />,
                },
                {
                    path: 'logbook/:id',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_USER]} element={LoadComponent({ component: ProjectLog })} />
                    ),
                },
                {
                    path: 'settings',
                    children: [
                        {
                            path: 'crew-types',
                            element: (
                                <PrivateRoute
                                    roles={[Role.ROLE_ADMIN]}
                                    element={LoadComponent({ component: CrewTypes })}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'steward-list',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_ADMIN, Role.ROLE_ADDON_STEWARD_MANAGER]}
                            element={LoadComponent({ component: StewardList })}
                        />
                    ),
                },
                {
                    path: 'supporter-demands',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_SUPPORTER_DEMAND]}
                            element={LoadComponent({ component: SupporterDemands })}
                        />
                    ),
                },
                {
                    path: 'crew-newsletter',
                    element: (
                        <PrivateRoute
                            roles={[Role.ROLE_USER]}
                            availableIf={
                                (params) => true
                                //SecurityHelper.isCrewManagerOrHigher(params.id, [Role.ROLE_PLENUM])
                            }
                            element={LoadComponent({ component: CrewNewsletter })}
                        />
                    ),
                },
                {
                    path: 'surveys/2017',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_USER]} element={LoadComponent({ component: Survey2017 })} />
                    ),
                },
                {
                    path: 'surveys/2023',
                    element: (
                        <PrivateRoute roles={[Role.ROLE_USER]} element={LoadComponent({ component: Survey2023 })} />
                    ),
                },
            ],
        },
    ]);
};

export { AllRoutes };
