import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    id: null,
    username: null,
    accessToken: null,
    refreshToken: null,
    authenticated: null,
    expiresAt: null,
    displayName: '',
    moduleActions: [],
    isSuperAdmin: false,
    unacceptedPrivacyStatements: null,
    personalDataIncomplete: null,
    lastActionAt: null,
    useDarkMode: true,
    roles: [],
    needsSecondFactor: null,
    hasUnacceptedPrivacyStatements: null,
    secondFactorValidityToken: null,
    firstname: '',
    lastname: '',
    nickname: '',
    currentProjectId: null,
    memberOfCrews: null,
    managerOfCrews: null,
    adminOfCrews: null,
    logoutWarningSound: null,
    tableStates: {
        crewList: {
            crewTypeFilter: [],
            crewSearchValue: '',
        },
        attendances: {
            foodVouchers: true,
            inCurrentProject: true,
            rowsExpanded: true,
            pageSize: 5,
        },
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            return {
                ...state,
                id: action.payload.id,
                username: action.payload.username,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                authenticated: true,
                expiresAt: action.payload.expiresAt,
                displayName: action.payload.displayName,
                moduleActions: action.payload.moduleActions,
                isSuperAdmin: action.payload.isSuperAdmin,
                unacceptedPrivacyStatements: action.payload.unacceptedPrivacyStatements,
                personalDataIncomplete: action.payload.personalDataIncomplete,
                roles: action.payload.roles,
                lastActionAt: new Date().toString(),
                needsSecondFactor: action.payload.needsSecondFactor,
                hasUnacceptedPrivacyStatements: action.payload.hasUnacceptedPrivacyStatements,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname,
                nickname: action.payload.nickname,
                memberOfCrews: action.payload.memberOfCrews,
                managerOfCrews: action.payload.managerOfCrews,
                adminOfCrews: action.payload.adminOfCrews,
                logoutWarningSound: action.payload.logoutWarningSound,
            };
        },
        updateDisplayName(state, action) {
            state.displayName = action.payload.displayName;
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.nickname = action.payload.nickname;
        },
        accessTokenSuccess(state, action) {
            state.authenticated = true;
            state.accessToken = action.payload.accessToken;
            state.expiresAt = action.payload.expiresAt;
        },
        secondFactorSuccess(state, action) {
            state.needsSecondFactor = false;
            state.secondFactorValidityToken = action.payload.secondFactorValidityToken;
        },
        privacyStatementsSuccess(state) {
            state.hasUnacceptedPrivacyStatements = false;
        },
        logLastAction(state) {
            state.lastActionAt = new Date().toString();
        },
        logout(state) {
            return {
                ...initialState,
                authenticated: false,
                secondFactorValidityToken: state.secondFactorValidityToken,
                useDarkMode: state.useDarkMode,
            };
        },
        lock(state) {
            return {
                ...initialState,
                authenticated: false,
                username: state.username,
                secondFactorValidityToken: state.secondFactorValidityToken,
                useDarkMode: state.useDarkMode,
                currentProjectId: state.currentProjectId,
            };
        },
        toggleUseDarkMode(state) {
            state.useDarkMode = !state.useDarkMode;
        },
        acceptPrivacyStatementSuccess(state, action) {
            state.unacceptedPrivacyStatements = state.unacceptedPrivacyStatements?.filter(
                (ps: any) => ps !== action.payload.privacyStatementId
            );
        },
        completedPersonalDataSuccess(state) {
            state.personalDataIncomplete = null;
        },
        setCurrentProjectId(state, action) {
            state.currentProjectId = action.payload.currentProjectId;
        },
        setCrewTypeFilter(state, action) {
            state.tableStates.crewList.crewTypeFilter = action.payload.selectedCrewFilter;
        },
        setSearchValue(state, action) {
            switch (action.payload.tableType) {
                case 'crewList':
                    state.tableStates.crewList.crewSearchValue = action.payload.value;
                    break;
                default:
                    break;
            }
        },
        setAttendanceTableStates(state, action) {
            switch (action.payload.filterType) {
                case 'foodVouchers':
                    state.tableStates.attendances.foodVouchers = action.payload.value;
                    break;
                case 'inCurrentProject':
                    state.tableStates.attendances.inCurrentProject = action.payload.value;
                    break;
                case 'rowsExpanded':
                    state.tableStates.attendances.rowsExpanded = action.payload.value;
                    break;
                case 'pageSize':
                    state.tableStates.attendances.pageSize = action.payload.value;
                    break;
                default:
                    break;
            }
        },
    },
});

export const {
    loginSuccess,
    updateDisplayName,
    accessTokenSuccess,
    secondFactorSuccess,
    logLastAction,
    logout,
    lock,
    toggleUseDarkMode,
    acceptPrivacyStatementSuccess,
    completedPersonalDataSuccess,
    setCurrentProjectId,
    privacyStatementsSuccess,
    setCrewTypeFilter,
    setSearchValue,
    setAttendanceTableStates,
} = authSlice.actions;

export default authSlice.reducer;
