import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { logout } from '../../redux/auth/authSlice';

export const unauthenticatedMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status === 401) {
            dispatch(logout());
            window.location.href = '/account/login';
        }

        return next(action);
    };
