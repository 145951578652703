enum Role {
    ROLE_USER = 'ROLE_USER',
    ROLE_PLENUM = 'ROLE_PLENUM',
    ROLE_CREW_CARE_ADMIN = 'ROLE_CREW_CARE_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_ADDON_KITCHEN_MONITOR = 'ROLE_ADDON_KITCHEN_MONITOR',
    ROLE_ADDON_STEWARD_MANAGER = 'ROLE_ADDON_STEWARD_MANAGER',
    ROLE_CHECK_IN = 'ROLE_CHECK_IN',
    ROLE_CASH_ADMIN = 'ROLE_CASH_ADMIN',
    ROLE_CASH_ASSISTANT = 'ROLE_CASH_ASSISTANT',
    ROLE_API = 'ROLE_API',
    ROLE_SUPPORTER_DEMAND = 'ROLE_SUPPORTER_DEMAND',
    ROLE_ADDON_CREW_ATTENDANCE_OVERVIEW = 'ROLE_ADDON_CREW_ATTENDANCE_OVERVIEW',
}
const roleHierarchies = [
    [
        Role.ROLE_USER,
        Role.ROLE_PLENUM,
        Role.ROLE_CASH_ASSISTANT,
        Role.ROLE_CREW_CARE_ADMIN,
        Role.ROLE_ADMIN,
        Role.ROLE_CASH_ADMIN,
    ],
    [Role.ROLE_USER, Role.ROLE_CHECK_IN, Role.ROLE_ADMIN],
];

export { Role, roleHierarchies };
